import React, { useEffect } from "react";
import Layout from "../Layout";
import "./style.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Approach() {
  gsap.registerPlugin(ScrollTrigger);
  const ref1 = React.useRef(null);
  let serviceref = React.createRef(null);
  useEffect(() => {
    gsap.to(".layout", {
      scrollTrigger: {
        trigger: serviceref.current,
        // markers: false,
        start: "-40% center",
        end: "110% center",
        refreshPriority: -1,
        pinSpacing: false,
        toggleClass: { targets: ".layout", className: "layout--bgblack" },
      },
    });

    gsap.from(".moveupanim", {
      scrollTrigger: {
        trigger: ref1.current,
        // markers: true,
        start: "-10% center",
        end: "100% center",
        toggleActions: "play none play reverse",
      },
      stagger: 0.1,
      y: 300,
      rotate: 10,
      duration: 0.3,
    });

    return () => {
      ScrollTrigger.getAll().forEach((instance) => {
        instance.kill();
      });
      // This in case a scroll animation is active while the route is updated
      gsap.killTweensOf(window);
    };
  }, []);

  return (
    <Layout>
      <div className="approach">
        <h1 className="approach__heading">Our approach</h1>

        <div className="approach__toptext">
          Dolor consectetur amet qui ullamco dolor magna. Ullamco nisi culpa
          veniam.
        </div>

        <div
          ref={ref1}
          className="grid grid-cols-1 lg:grid-cols-3  approach__cardscontainer"
        >
          <div className="approach__card moveupanim">
            <div className="approach__card__heading">Smart</div>
            <div className="approach__card__text">
              Labore mollit adipisicing velit aliqua reprehenderit velit.
              Nostrud amet aliquip dolor esse et commodo ut. Eiusmod voluptate
              reprehenderit esse voluptate elit incididunt elit.
            </div>
          </div>
          <div className="approach__card moveupanim">
            <div className="approach__card__heading ">Simple</div>
            <div className="approach__card__text">
              Labore mollit adipisicing velit aliqua reprehenderit velit.
              Nostrud amet aliquip dolor esse et commodo ut. Eiusmod voluptate
              reprehenderit esse voluptate elit incididunt elit.
            </div>
          </div>
          <div className="approach__card moveupanim">
            <div className="approach__card__heading">Straight</div>
            <div className="approach__card__text">
              Labore mollit adipisicing velit aliqua reprehenderit velit.
              Nostrud amet aliquip dolor esse et commodo ut. Eiusmod voluptate
              reprehenderit esse voluptate elit incididunt elit.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
